import {
  ComponentType,
  FunctionComponent,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useLocation } from 'react-router-dom';
import { genColorLog, log } from '../logger';

interface DataLayerWindow extends Window {
  dataLayer?: Array<unknown>;
}

declare let window: DataLayerWindow;

type LocationLike = Pick<Location, 'pathname' | 'search' | 'hash'>;

type ExtraData = {
  nonInteraction?: boolean;
  exp?: string;
};

const GA_ID = process.env.GA_ID as string;
const GTM_ID = process.env.GTM_ID as string;

export type GTMContextProps = {
  dataLayerPush: (data: unknown) => void;
  /**
   * NOTE - this is called automatically by
   * GTMListener (so only call for synthetic
   * pages, like an ad click)
   */
  dataLayerHistoryChange: (opts?: {
    location?: LocationLike;
    extraData?: ExtraData;
    noTrack?: boolean;
  }) => void;
};

const GTMContext = createContext<GTMContextProps | undefined>(undefined);

const STYLE = 'color:#061;background:#DFE;';
const colorLog = genColorLog(STYLE);

const IGNORE_PATHS = [
  '/auth/signin',
  '/auth/signout',
  '/auth/signin/',
  '/auth/signout/',
];

type GTMListenerV2Props = {
  runOnMount?: () => void;
};

const GTMListener: FunctionComponent<GTMListenerV2Props> = ({
  children,
  runOnMount,
}) => {
  const location = useLocation();

  const prevPathRef = useRef('');
  const prevExtraDataRef = useRef<ExtraData | undefined>(undefined);

  /**
   * data layer history change
   * @param opts.location window.location
   * @param opts.extraData extra info to pass into the GTM event
   * @param opts.noTrack set this to skip tracking this event (useful for ab tests we want to avoid counting)
   */
  const dataLayerHistoryChange = useCallback(
    ({
      location,
      extraData,
      noTrack = false,
    }: {
      location?: LocationLike;
      extraData?: ExtraData;
      noTrack?: boolean;
    } = {}) => {
      location = location || window.location;
      const path = _getPath(location); // include query string
      const prevPath = prevPathRef.current;
      const prevExtraData = prevExtraDataRef.current;
      if (
        path === prevPath &&
        (!extraData ||
          JSON.stringify(extraData) === JSON.stringify(prevExtraData))
      ) {
        return;
      }
      prevPathRef.current = path;
      prevExtraDataRef.current = extraData;
      const data = {
        event: 'fp_history_change',
        page: path,
      };
      if (extraData) {
        Object.assign(data, extraData);
      }

      if (noTrack) {
        log.warn(
          `%cSkipped dataLayerHistoryChange because of noTrack: ${JSON.stringify(
            data,
          )}`,
          'background:#c24;color:white;',
        );
        return;
      }
      dataLayerPush(data);
    },
    [],
  );

  const value = useMemo(
    () => ({
      dataLayerPush,
      dataLayerHistoryChange,
    }),
    [dataLayerHistoryChange],
  );

  useEffect(() => {
    colorLog('[GTMListener.COMPONENT_DID_MOUNT]');

    if (runOnMount) {
      colorLog('[GTMListener.runOnMount]');
      Promise.resolve()
        .then(() => runOnMount())
        .then((data) => colorLog('[GTMListener.runOnMount.then]', data))
        .catch((err) => colorLog('[GTMListener.runOnMount.error]', err));
    }
  }, [runOnMount]);

  useEffect(() => {
    if (_isAutoPath(location)) {
      dataLayerHistoryChange({ location });
    }
  }, [location]);

  return <GTMContext.Provider value={value} children={children} />;
};

GTMListener.displayName = 'GTMListener';

export default GTMListener;

/**
 * the raw data layer push api
 * @param {object} data - any data to pass into the dataLayer
 */
export const dataLayerPush = (data: unknown) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};

/**
 * Hook for access the GTM props
 */
export const useGTM = () => {
  const context = useContext(GTMContext);
  if (context == null) {
    throw new Error(`Must call hook inside GTMContextProvider`);
  }

  return context;
};

// export const connectGTM = <DOMException, P extends  // TODO // NEXT - finish the HocProps wrapper and swap this out for the other GTMListener, then start sending events for the homepage on whether we render the new or old page

/**
 * HOC to access GTM props
 */
export const connectGTM = <P extends GTMContextProps>(
  Comp: ComponentType<P>,
) => {
  type HocProps = Omit<P, keyof GTMContextProps>;

  const Wrapped: FunctionComponent<HocProps> = (props: HocProps) => {
    const gtmProps = useGTM();
    const compProps = { ...props, ...gtmProps } as P;
    return <Comp {...compProps} />;
  };

  return Wrapped;
};

export const setupGTM = () => {
  const gtmScriptId = '__gtmlistener__script';

  const enc = (x: string | number | boolean) => encodeURIComponent(x);

  const existingScript = document.getElementById(gtmScriptId);

  const dataLayer = (window.dataLayer = window.dataLayer || []);
  dataLayer.push({
    ga_id: GA_ID,
    original_location: window.location.href.split('#')[0],
  });

  if (!existingScript) {
    (function (
      w: DataLayerWindow,
      d: Document,
      s: 'script',
      l: 'dataLayer',
      i: string,
    ) {
      w[l] = w[l] || [];
      w[l]!.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.id = gtmScriptId; // OVERRIDE - handle hot module stuff
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + enc(i) + dl;
      f.parentNode!.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', GTM_ID);
  }
};

// ## Helpers

const _getPath = (location: LocationLike) => {
  return `${location.pathname}${location.search}`;
};

const _isAutoPath = (location?: LocationLike) => {
  const pathname = (location || window.location).pathname;
  return IGNORE_PATHS.indexOf(pathname) === -1;
};
