import { useMemo } from 'react';
import { UserInfo } from '../auth/AuthProvider';

/**
 * A hook that extracts the social sign-in provider from the user login data if it exists.
 * @param user - The AWS Amplify user object.
 * @returns The provider name string if found otherwise 'undefined'.
 */
export function useUserProvider(user?: UserInfo) {
  return useMemo(() => getUserProvider(user), [user]);
}

/**
 * A function extracts the social sign-in provider from the user login data if it exists.
 * @param user - The AWS Amplify user object.
 * @returns The provider name string if found otherwise 'undefined'.
 */
export function getUserProvider(user?: UserInfo | null) {
  if (!user?.attributes) {
    return;
  }
  const identitiesString = user.attributes.identities;
  if (identitiesString) {
    const identities = JSON.parse(identitiesString);
    if (identities.length) {
      const provider = identities[0].providerName;
      return provider;
    }
  }
}

/** Returns the user facing text describing the user provider. */
export function getUserProviderText(userProvider: string) {
  return userProvider ? `Continue with ${userProvider}` : 'Email & Password';
}
