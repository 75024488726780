import { Amplify } from '@aws-amplify/core';
import { useEffect } from 'react';
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { logVisitSite } from '../abtest';
import AuthProvider from '../auth/AuthProvider';
import { EXTENSION_APIS, PATH_CHECKOUT } from '../constants';
import { AccountContextProvider } from '../context/AccountContext';
import { AlertManagerProvider } from '../context/AlertManager';
import { ExtensionProvider } from '../extension/Provider';
import LoginListener from '../extension/login';
import { checkUrl } from '../extension/redir-state';
import ImageHostingApp from '../imagehosting';
import { genColorLog } from '../logger';
import AboutUsPage from '../pages/AboutUsPage';
import AccessibilityPage from '../pages/AccessibilityPage';
import AccountPage from '../pages/AccountPage';
import AuthPage from '../pages/AuthPage';
import CheckoutPage from '../pages/CheckoutPage';
import ComingSoonPage from '../pages/ComingSoonPage';
import ContactUsPage from '../pages/ContactUsPage';
import DemoPage from '../pages/DemoPage';
import EnterpriseAccountPage from '../pages/EnterpriseAccountPage';
import EnterprisePage from '../pages/EnterprisePage';
import FaqPage from '../pages/FaqPage';
import HomePage from '../pages/HomePage';
import InlineDemoEditorPage from '../pages/InlineDemoEditorPage';
import InvitesPage from '../pages/InvitesPage';
import LegalPage from '../pages/LegalPage';
import NewsletterPage from '../pages/NewsletterPage';
import NoMatchPage from '../pages/NoMatchPage';
import PopupLoginPage from '../pages/PopupLoginPage';
import PremiumPage from '../pages/PremiumPage';
import PressPage from '../pages/PressPage';
import PrivacyPage from '../pages/PrivacyPage';
import SalesPage from '../pages/SalesPage';
import StyleGuidePage from '../pages/StyleGuidePage';
import TermsOfUsePage from '../pages/TermsOfUsePage';
import TestExtensionIdSetter from '../pages/TestExtensionIdSetter';
import { awsConfig } from '../utils/oauth-config';
import Footer from './Footer';
import GTMListener from './GTMListener';
import Header from './Header';
import ScrollToTop from './ScrollToTop';

Amplify.configure(awsConfig);

const colorLog = genColorLog('color:#666;background:#eee');

colorLog(
  `[App.OAUTH.${process.env.ENV}]: ${JSON.stringify(awsConfig.oauth, null, 2)}`,
);

// TODO(growthbook)
// const gb = new GrowthBook({
//   apiHost: 'https://cdn.growthbook.io',
//   clientKey: 'sdk-mJlKv8D31YzQZ1',
//   enableDevMode: true,
//   trackingCallback: (experiment, result) => {
//     console.log('%cZOMG trackingCallback!!', 'background:pink'); //REMMM
//     console.log('%cview_experiment', 'background:#AFA', {
//       experimentId: experiment.key,
//       variationId: result.key,
//     }); //REMMM
//     dataLayerPush({
//       event: 'view_experiment',
//       exp_id: experiment.key,
//       exp_var: result.key,
//     });
//   },
// });
// gb.init({ streaming: !IS_MASTER });

const HIDE_HEADER_PREFIXES = [
  '/connect',
  '/auth',
  '/demo-editor-inline',
  '/images',
];

const canShowHeader = (location) => {
  const pathname = location.pathname;
  const canShow = !HIDE_HEADER_PREFIXES.find(
    (prefix) => pathname === prefix || pathname.startsWith(`${prefix}/`),
  );
  document.documentElement.classList.toggle('no-header', !canShow);
  return canShow;
};

function App() {
  useEffect(() => {
    checkUrl();
  }, []);

  // TODO(growthbook)
  // // growthbook
  // useEffect(() => {
  //   let isMounted = true;
  //   waitForGaAnonymousId()
  //     .then((anonymousId) => {
  //       if (!isMounted) {
  //         return;
  //       }
  //       console.log(`%cGB.SET ID=${anonymousId}`, 'background:yellow;font-weight:bold');
  //       gb.setAttributes({ id: anonymousId });
  //     })
  //     .catch((err) => {
  //       log.error(err);
  //     });
  //   return () => {
  //     isMounted = false;
  //   };
  // }, []);

  return (
    <div id="content">
      {/* // TODO(growthbook) <GrowthBookProvider growthbook={gb}> */}
      <AlertManagerProvider>
        <AuthProvider>
          <ExtensionProvider
            extensionApiUrls={EXTENSION_APIS}
            ListenerClass={LoginListener}
          >
            <AccountContextProvider>
              <Router>
                <Switch>
                  <Route
                    exact
                    path="/_test"
                    component={TestExtensionIdSetter}
                  />
                  <Route
                    render={({ location }) => (
                      <GTMListener runOnMount={logVisitSite}>
                        <ScrollToTop>
                          {/* min-h-[calc(100vh-220px)] */}
                          <div className="min-h-[calc(100vh-204px)]">
                            {canShowHeader(location) && <Header />}
                            <Switch>
                              <Route
                                exact
                                path="/index.html"
                                render={() => <Redirect to="/" />}
                              />
                              <Route
                                path="/"
                                exact
                                render={(props) => <HomePage {...props} />}
                              />
                              <Route path="/auth" component={AuthPage} />
                              <Route
                                path="/connect"
                                component={PopupLoginPage}
                              />
                              <Route path="/demos" component={DemoPage} />
                              <Route
                                path="/images"
                                component={ImageHostingApp}
                              />
                              <Route
                                path="/demo-editor-inline"
                                component={InlineDemoEditorPage}
                              />
                              <Route
                                path="/faq"
                                render={(props) => <FaqPage {...props} />}
                              />
                              <Route path="/legal" component={LegalPage} />
                              <Route
                                exact
                                path="/enterprise"
                                component={EnterprisePage}
                              />
                              <Route
                                exact
                                path="/about"
                                component={AboutUsPage}
                              />
                              <Route
                                exact
                                path="/sales"
                                component={SalesPage}
                              />
                              <Route
                                path="/newsletter"
                                component={NewsletterPage}
                              />
                              <Route
                                path="/contact"
                                component={ContactUsPage}
                              />
                              <Route path="/premium" component={PremiumPage} />
                              <Route path="/press" component={PressPage} />
                              <Route path="/privacy" component={PrivacyPage} />
                              <Route
                                path="/accessibility"
                                component={AccessibilityPage}
                              />
                              <Route
                                path="/terms-of-use"
                                component={TermsOfUsePage}
                              />
                              <Route path="/account" component={AccountPage} />
                              <Route
                                path="/enterprise/account"
                                component={EnterpriseAccountPage}
                              />
                              <Route path="/invites" component={InvitesPage} />
                              <Route
                                path={PATH_CHECKOUT}
                                component={CheckoutPage}
                              />
                              <Route
                                path="/style-guide"
                                component={StyleGuidePage}
                              />
                              <Route
                                path="/coming-soon"
                                component={ComingSoonPage}
                              />
                              <Route component={NoMatchPage} />
                            </Switch>
                          </div>
                          {canShowHeader(location) && <Footer />}
                        </ScrollToTop>
                      </GTMListener>
                    )}
                  />
                </Switch>
              </Router>
            </AccountContextProvider>
          </ExtensionProvider>
        </AuthProvider>
      </AlertManagerProvider>
      {/* // TODO(growthbook) </GrowthBookProvider> */}
    </div>
  );
}

App.displayName = 'App';

export default App;
