import assert from 'minimalistic-assert';
import { Helmet } from 'react-helmet';
import AsSeenInWidget from '../components/AsSeenInWidget';
import { Container, PrimaryButton, Section } from '../components/Base';
import Benefits from '../components/Benefits';
import Emojis from '../components/Emojis';
import Grid from '../components/Grid';
import { FPSCFreeInfoCard, PremiumInfoCard } from '../components/InfoCard';
import Review from '../components/Review';
import Video from '../components/Video';
import { Stars } from '../components/icons/Star';
import {
  CAPTURE_VIDEO,
  IS_EDGE,
  STATS,
  WEBSTORE_LINK,
  WEBSTORE_LINK_CHROME,
  WEBSTORE_LINK_EDGE,
} from '../constants';
import connectExtension, {
  EXTENSION_PROPS,
  getExtensionLanding,
} from '../extension/Provider';
import { getTitle } from '../utils/document';
import { tr } from '../utils/e7n-util';
import './HomePage.scss';
import page from './HomePageData.ts';

import classNames from 'classnames';
import editorHp from '../../assets/img/hp-big-580.png';
import editorHp2x from '../../assets/img/hp-big-580@2x.png';
import screenshotHp from '../../assets/img/hp-small-290.png';
import screenshotHp2x from '../../assets/img/hp-small-290@2x.png';
import { useGTM } from '../components/GTMListener';

assert(CAPTURE_VIDEO, 'CAPTURE_VIDEO is not defined');

const HomePage = ({ extensionIsInstalled, extensionOrigin }) => {
  const gtm = useGTM();

  const featShowOldHomePage = false; // TODO(growthbook) useFeatureIsOn('hp-redesign-2024-09');

  // console.log(
  //   `%cWHAT IS CLIENT_FEATURE: ${featShowOldHomePage} (${typeof featShowOldHomePage})`,
  //   'background:yellow;font-weight:bold',
  // );

  const ctaButton = extensionIsInstalled ? (
    <PrimaryButton
      className="btn-standout"
      type="link"
      size="large"
      href={getExtensionLanding(extensionOrigin)}
    >
      Go to extension
    </PrimaryButton>
  ) : (
    <PrimaryButton
      className="btn-standout"
      type="link"
      size="large"
      href={WEBSTORE_LINK}
    >
      {IS_EDGE ? 'Add to Edge (it’s free!)' : 'Add to Chrome (it’s free!)'}
    </PrimaryButton>
  );

  const ctaSubtext = IS_EDGE ? 'Chrome browser' : 'Edge browser';
  const ctaSubtextLink = IS_EDGE ? WEBSTORE_LINK_CHROME : WEBSTORE_LINK_EDGE;

  const benefits = page.benefits;

  _substituteStats(benefits);

  // HACK - do smart line-spacing of title for english
  const rawTitle =
    'The best browser extension for taking a screenshot of an entire webpage';
  let title = tr(rawTitle);
  if (title === rawTitle) {
    title =
      'The best browser extension\n for taking a screenshot\n of an entire webpage';
  }

  const params = new URLSearchParams(window.location.search);

  let showNew = !featShowOldHomePage;

  return (
    <div className="homepage">
      <Helmet>
        <title>{getTitle()}</title>
      </Helmet>
      {showNew ? (
        <Section className="hero-sec first-sec">
          <Container className="pt-[40px] md:flex md:max-w-[1160px] md:justify-between md:gap-[40px]">
            <div className="mx-auto mb-[40px] md:mb-0 lg:mt-[20px]">
              <h1 className="mb-0 mt-0 !pt-0 text-[60px] font-normal leading-[72px] tracking-[-1.5px] xl:text-[80px] xl:leading-[96px]">
                GoFullPage
              </h1>
              {/* <h1 className="homepage--name">GoFullPage</h1> */}
              <div className="mx-auto mb-[20px] max-w-[315px] text-[20px] leading-[28px] md:mb-[40px] md:min-w-[333px] md:max-w-full xl:text-[22px] xl:leading-[30px]">
                The most reliable full page screenshot{' '}
                <span className="inline md:block">in just a single click</span>
              </div>

              <div className="mb-[16px]">
                <PrimaryButton
                  className="btn-standout btn-convert px-[30px]"
                  type="link"
                  size="medium"
                  href={WEBSTORE_LINK} // TODO(hp-2024-06) - make sure this works on Edge
                >
                  Get started (it’s free)
                </PrimaryButton>
              </div>

              <div className="">
                <Stars count={5} alt="Star" /> {STATS.ratings} · Trusted by{' '}
                {STATS.usersLong}
                <br />
                Also available on the <a href={ctaSubtextLink}>{ctaSubtext}</a>
              </div>
            </div>
            <HeroImage className="mx-auto w-full shrink md:mx-0 md:max-w-[min(638px,60%)]" />
          </Container>
        </Section>
      ) : (
        <Section className="hero-sec first-sec">
          <Container>
            <h1>
              <span className="homepage--name">GoFullPage</span>
              <span className="homepage--name--info pre-line">{title}</span>
            </h1>
            <Emojis space={true} />
            {ctaButton}

            <div className="homepage--sub-text">
              <p>
                <Stars count={5} alt="Star" /> {STATS.ratings} · Trusted by{' '}
                {STATS.usersLong}
                <br />
                Also available on the <a href={ctaSubtextLink}>{ctaSubtext}</a>
              </p>
            </div>
          </Container>
        </Section>
      )}

      <Section className="info-sec">
        <Container>
          <Benefits benefits={benefits} />
        </Container>
      </Section>

      <Section className="blue-sec" bg="link">
        <Container>
          <p>See capturing a full page screenshot in action!</p>
          <Video
            {...CAPTURE_VIDEO}
            preload={'none'}
            autoPlay={false}
            loop={false}
          />
        </Container>
      </Section>

      <AsSeenInWidget />

      <Section className="revs-sec">
        <Container>
          <h2>See what people are saying</h2>
          <p className="subheader">
            Real reviews, from the{' '}
            <a
              className="sly"
              href="https://chrome.google.com/webstore/detail/full-page-screen-capture/fdpohaocaechififmbbbbbknoalclacl?hl=en-US"
            >
              Chrome Web Store extension page
            </a>
            .
          </p>
          <Grid
            cols={2}
            elts={page.reviews.map((r, i) => (
              <Review key={i} {...r} />
            ))}
          />
        </Container>
      </Section>

      <Section>
        <Container>
          <div className="info-cards">
            <FPSCFreeInfoCard />
            <PremiumInfoCard buttonText="Learn more" />
          </div>
        </Container>
      </Section>
    </div>
  );
};

HomePage.propTypes = Object.assign({}, EXTENSION_PROPS);

// HACK - convert things like: "With {STATS.ratings} ratings"
// into "With 26K ratings" via the constants.STATS field
const _substituteStats = (benefits) => {
  const rStats = /\{STATS\.([^\}]+)\}/g;
  benefits.forEach((benefit) => {
    const sp = benefit.text.split(rStats);
    if (sp.length > 1) {
      benefit.text = sp
        .map((tok, i) => (i % 2 === 1 ? STATS[tok] : tok))
        .join('');
    }
  });
};

export default connectExtension(HomePage);

// ## Helpers

const HeroImage = ({ className }) => {
  return (
    <div className={classNames('relative', className)}>
      {/* w-82% at mobile so we can center on middle of main image */}
      {/* ml-9.09% and max-w-90.9% to create offset for the smaller image */}
      <img
        src={editorHp}
        srcSet={`${editorHp2x} 2x`}
        className="ml-[9.090909%] block aspect-[58/37] w-[82%] max-w-[90.90909%] shadow-md md:w-full"
      />
      <img
        src={screenshotHp}
        srcSet={`${screenshotHp2x} 2x`}
        className="img-w-45 absolute left-0 top-[16%] shadow-lg"
      />
    </div>
  );
};
